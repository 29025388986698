import { styled } from "@mui/material/styles";
import { Table as AntdTable, Spin, TableColumnsType } from "antd";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { useEffect, useMemo, useState } from "react";
import {
  EntryView,
  getDataSource,
  getExcelBlob,
  getGroups,
  getSharedColumns,
  GroupBy,
  IGNORE_TAGS,
  MAX_PERIODS,
  SharedColumn,
  useQueryEntries,
} from "./util";

const Table = styled(AntdTable<EntryView>)({
  "& th": {
    verticalAlign: "bottom",
  },
  "& th, td": {
    fontSize: "8pt",
    padding: "1px !important",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // antd ellipsis is enabled on some columns to set the title attribute
    textOverflow: "clip !important",
  },
  "& tr.selected td": {
    backgroundColor: "yellow !important",
  },
});

function sharedColumnToTable<T>(
  col: SharedColumn<T>
): TableColumnsType<T>[number] {
  return {
    title: col.title,
    width: col.width,
    key: col.key,
    fixed: col.fixed,
    align: col.align,
    ellipsis: col.ellipsis,
    onHeaderCell: col.onHeaderCell,
    onCell: col.onCell,
    render: col.render,
    children: col.children?.map(sharedColumnToTable),
  };
}

const useTableHeight = () => {
  const [tableHeight, setTableHeight] = useState(400);
  // Update the height based on the viewport height
  useEffect(() => {
    const updateTableHeight = () => {
      // Adjust the height as needed (subtracting for any fixed headers/footers)
      const height = window.innerHeight - 280;
      setTableHeight(height);
    };

    // Initial height calculation
    updateTableHeight();

    // Add event listener to adjust the table height when the window is resized
    window.addEventListener("resize", updateTableHeight);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", updateTableHeight);
  }, []);

  return tableHeight;
};

export function Portfolio(props: {
  portfolioId: number;
  date: dayjs.Dayjs;
  periods: number;
  lookthrough: boolean;
  groupBy: GroupBy;
  excelFlag: number;
}) {
  const { date, lookthrough, periods, portfolioId } = props;

  const [selectedRowKey, setSelectedRowKey] = useState<string | number>();
  const [collapsedGroups, setCollapsedGroups] = useState(new Set(IGNORE_TAGS));

  const tableColumns: TableColumnsType<EntryView> = useMemo(() => {
    const sharedColumns = getSharedColumns(
      date,
      periods,
      collapsedGroups,
      setCollapsedGroups,
      false
    );
    return sharedColumns.map(sharedColumnToTable);
  }, [date, periods, collapsedGroups, setCollapsedGroups]);

  const { data, loading, error, refetch } = useQueryEntries({
    date: date.format("YYYY-MM-DD"),
    lookthrough,
    periods: MAX_PERIODS,
    portfolioId,
  });

  const portfolio = data?.portfolio;

  const groups = useMemo(
    () => getGroups(props.groupBy, portfolio),
    [portfolio, props.groupBy]
  );

  const dataSource = useMemo(
    () => getDataSource(collapsedGroups, groups),
    [groups, collapsedGroups]
  );

  const excelDataSource = useMemo(
    () => getDataSource(undefined, groups),
    [groups]
  );

  // used for cypress testing
  const [excelFilename, setExcelFilename] = useState("");

  useEffect(() => {
    setExcelFilename("");
    if (!props.excelFlag) return;
    if (!excelDataSource) return;
    getExcelBlob(excelDataSource, date, periods).then(blob => {
      const filename = `asset-allocation-${Date.now().toString(36)}.xlsx`;
      saveAs(blob, filename);
      setExcelFilename(filename);
    });
  }, [props.excelFlag]);

  const tableHeight = useTableHeight();

  if (loading) return <Spin />;
  if (!dataSource) return null;

  return (
    <>
      <Table
        data-cy="asset-allocation-grid"
        columns={tableColumns}
        dataSource={dataSource}
        scroll={{ x: "100%", y: tableHeight }}
        pagination={false}
        bordered
        size="small"
        tableLayout="fixed"
        onRow={entry => {
          return {
            className: entry.key === selectedRowKey ? "selected" : undefined,
            onClick: () => {
              setSelectedRowKey(entry.key);
            },
          };
        }}
      />
      {/* used for cypress testing */}
      <input type="hidden" name="excelFilename" value={excelFilename} />
    </>
  );
}
