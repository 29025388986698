import { gql } from "_graphql-types/gql";

export const DOCUMENTS_SEARCH = gql(/* GraphQL */ `
  query documentSearchResults(
    $investmentId: Int
    $firmId: Int
    $page: PageInput
    $typeId: Int
    $investmentOrFirmId: InvestmentOrFirmId
    $firmFilters: [FirmSearchFilters!]
    $investmentFilters: [InvestmentSearchFilters!]
    $companyFilters: [CompanySearchFilters!]
    $name: String
    $extensions: [String!]
    $text: String
    $minDate: DateTime
    $maxDate: DateTime
    $companyId: Int
    $dealId: Int
    $companyOrDealId: CompanyOrDealId
    $categoryId: Int
    $ids: [Int!]
    $sort: DocumentSort!
    $accessLevel: Int
  ) {
    documents(
      filter: {
        investmentId: $investmentId
        firmId: $firmId
        companyId: $companyId
        dealId: $dealId
        companyOrDealId: $companyOrDealId
        documentTypeEnumId: $typeId
        investmentOrFirmId: $investmentOrFirmId
        investmentFilters: $investmentFilters
        firmFilters: $firmFilters
        companyFilters: $companyFilters
        name: $name
        extensions: $extensions
        text: $text
        minDate: $minDate
        maxDate: $maxDate
        ids: $ids
        documentCategoryEnumId: $categoryId
        accessLevel: $accessLevel
      }
      page: $page
      sort: [$sort]
    ) {
      items {
        ...DocumentShow
      }
      nextPage {
        hasMore
      }
      total
    }
  }
`);

export const DOCUMENT_MANY = gql(/* GraphQL */ `
  query DocumentMany($documentIds: [Int!]!) {
    documentMany(ids: $documentIds) {
      id
      name
      signedUrl
    }
  }
`);

export const DOCUMENT_ENUMS = gql(/* GraphQL */ `
  query investmentDocumentsTypeEnums(
    $filter: DocumentFilter
    $sort: [DocumentCountByDocumentTypeSort!]
    $includeYear: Boolean
  ) {
    documentCountByDocumentType(
      filter: $filter
      sort: $sort
      includeYear: $includeYear
    ) {
      id
      name
      year
      count
    }
  }
`);

export const DOCUMENT_EXTENSIONS = gql(/* GraphQL */ `
  query documentFileExtensions {
    documentFileExtensions
  }
`);

export const INVESTMENT_GALLERY = gql(/* GraphQL */ `
  query InvestmentGallery($investmentId: Int!) {
    documents(filter: { investmentId: $investmentId, gallery: true }) {
      items {
        id
        name
        signedUrl
        signedThumbnailUrl
      }
    }
  }
`);

export const BULK_DOWNLOAD = gql(/* GraphQL */ `
  query bulkDownload($ids: [Int!]!) {
    documentDownloadBatch(ids: $ids) {
      total
      url
    }
  }
`);

export const DOCUMENT_QA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription DocumentQASubscription($sessionId: String!) {
    questionResponses(sessionId: $sessionId) {
      conversationId
      consultedExcerpts {
        id
        text
      }
      sessionId
      messageStream {
        message
        messageIndex
        contentType
        blockIndex
        event
        id
      }
    }
  }
`);

export const DOCUMENT_QUESTION = gql(/* GraphQL */ `
  query documentQuestion(
    $question: String!
    $entityId: Int!
    $entityType: ChatEntityType!
    $conversationId: Int
    $sessionId: String!
  ) {
    documentQuestion(
      question: $question
      entityId: $entityId
      entityType: $entityType
      conversationId: $conversationId
      sessionId: $sessionId
    ) {
      answer
      conversationId
      consultedExcerpts {
        id
        text
      }
    }
  }
`);

export const SPECIFIC_DOCUMENT_QUESTION = gql(/* GraphQL */ `
  query documentQuestionSpecific(
    $question: String!
    $documentId: Int!
    $conversationId: Int
    $sessionId: String!
  ) {
    documentQuestionSpecific(
      question: $question
      documentId: $documentId
      conversationId: $conversationId
      sessionId: $sessionId
    ) {
      answer
      conversationId
    }
  }
`);

export const VECTORIZE_DOCUMENT = gql(/* GraphQL */ `
  mutation generateEmbeddings($documentId: Int!) {
    generateEmbeddings(documentId: $documentId)
  }
`);

export const DOCUMENT_SHOW_QUERY = gql(/* GraphQL */ `
  query documentShowQuery($id: Int!) {
    document(id: $id) {
      ...DocumentShow
    }
  }
`);
