import { useQuery } from "@apollo/client";
import * as types from "_graphql-types/graphql";
import { DatePicker as AntDatePicker } from "antd";
import StatisticsTable from "Components/show/overview/statistics_table";
import { getQuarter, lastDayOfQuarter } from "date-fns";
import { toPercent } from "Helpers/index";
import i18n from "i18next";
import moment, { Moment } from "moment";
import numeral from "numeral";
import momentConfig from "rc-picker/lib/generate/moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  FETCH_INVESTMENT_PRIVATE_POSITIONS,
  POSITION_REPORT_DATES,
} from "./graphql";

const DatePicker = AntDatePicker.generatePicker(momentConfig);

const formatters = {
  investedAmount: (value: number | null) =>
    (value || value === 0) && numeral(value).format("$0.0 a"),
  realizedAmount: (value: number | null) =>
    (value || value === 0) && numeral(value).format("$0.0 a"),
  unrealizedAmount: (value: number | null) =>
    (value || value === 0) && numeral(value).format("$0.0 a"),
  totalValue: (value: number | null) =>
    (value || value === 0) && numeral(value).format("$0.0 a"),
  weight: (value: number | null) => toPercent(value),
  MOIC: (value: number | null) =>
    (value || value === 0) && `${numeral(value).format("0.0")}x`,
};

/* eslint-disable-next-line max-lines-per-function */
function InvestmentPrivatePositionsTable({
  investmentId,
}: {
  investmentId: number;
}): JSX.Element {
  const [date, setDate] = useState<Date | null>(null);

  const { loading, error, data } = useQuery<
    types.InvestmentPrivatePositionsQuery,
    types.InvestmentPrivatePositionsQueryVariables
  >(FETCH_INVESTMENT_PRIVATE_POSITIONS, {
    variables: {
      positionFilter: {
        reportDate: date,
      },
      page: {
        limit: 100,
      },
      sort: [
        {
          field: types.PositionSortEnum.Weight,
          order: types.SortInput.Desc,
        },
      ],
      id: investmentId,
    },
    skip: !date,
  });

  const { data: datesData, loading: datesLoading } = useQuery<
    types.GetPositionReportDatesQuery,
    types.GetPositionReportDatesQueryVariables
  >(POSITION_REPORT_DATES, {
    variables: {
      investmentId,
    },
  });

  useEffect(() => {
    if (datesData && datesData.positionReportDates[0]) {
      setDate(new Date(datesData.positionReportDates[0]));
    }
  }, [datesData]);

  const name = data?.investment?.name || "";
  const positions = useMemo(
    () => data?.investment?.positions?.items || [],
    [data]
  );

  const updateDate = (newDate: moment.Moment | null) => {
    if (!newDate) return;
    const endOfQuarter = lastDayOfQuarter(newDate.toDate());
    setDate(endOfQuarter);
  };

  const disabledTime = useCallback(
    (selectionDate: Moment) =>
      !datesData?.positionReportDates
        .map(dateDatum => new Date(dateDatum))
        .some(
          (dateDatum: Date) =>
            selectionDate.quarter() === getQuarter(dateDatum) &&
            selectionDate.year() === dateDatum.getFullYear()
        ),
    [datesData]
  );

  if (error) {
    return (
      <span>
        Something went wrong fetching positions
        <span hidden>{JSON.stringify(error, null, 2)}</span>
      </span>
    );
  }

  return (
    <div>
      {error && (
        <div className="error">
          Something went wrong, please contact support.
          {JSON.stringify(error, null, 2)}
        </div>
      )}
      <div className="summary-heading" data-cy="positions">
        <h3 className="invt-tab__title" id="positions">
          {i18n.t("overview.positions")}
        </h3>

        <div className="summary-heading__desc-wrap">
          {(loading || datesLoading) && (
            <i
              data-testid="loading-spinner"
              className="fa fa-spinner fa-spin"
            />
          )}
          {date && (
            <>
              <span className="summary-heading__desc-label">
                {i18n.t("date.as_of", { date: "" })}
              </span>

              <div className="rc-calendar-custom-wrap width-120">
                <DatePicker
                  picker="quarter"
                  format="[Q]Q yyyy"
                  disabledDate={disabledTime}
                  allowClear={false}
                  placeholder="Set the date"
                  defaultValue={moment(date)}
                  onChange={updateDate}
                  data-cy="quarter-picker"
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div>
        <div id="positions-table" className="positions-table">
          {positions.length > 0 &&
            `Source Date: ${positions[0].sourceDate.substring(0, 10)}`}
          <StatisticsTable
            dataMapping={positions}
            keyOrder={[
              "name",
              "weight",
              "initialInvestmentDate",
              "investedAmount",
              "unrealizedAmount",
              "realizedAmount",
              "totalValue",
              "MOIC",
            ]}
            headers={[
              "Name",
              "% of Current Portfolio",
              "Initial Date",
              "Invested",
              "Unrealized",
              "Realized",
              "Total",
              "MOIC",
            ]}
            fileName={`${name} ${date?.getFullYear()} ${
              date ? date.getMonth() + 1 : date
            } private positions`}
            sortableKeys={[
              "name",
              "totalValue",
              "weight",
              "initialInvestmentDate",
              "investedAmount",
              "unrealizedAmount",
              "realizedAmount",
              "MOIC",
            ]}
            numberKeys={[
              "weight",
              "investedAmount",
              "unrealizedAmount",
              "realizedAmount",
              "MOIC",
            ]}
            formatters={formatters}
          />
        </div>
      </div>
    </div>
  );
}

export default InvestmentPrivatePositionsTable;
