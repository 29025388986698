import React, { useMemo, useState } from "react";
import { ChatComponent } from "Components/ChatBox";
import { ChatMessage } from "Components/ChatBox/types";
import { useLazyQuery, useSubscription } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ChatToolType } from "_graphql-types/graphql";
import { AGENT_CHAT, CONTEXTUAL_CHAT_SUBSCRIPTION } from "./graphql";
import { ToolDisplays } from "./toolDisplays";
import { Spin, Checkbox, Row, Col, Card } from "antd";
import * as types from "_graphql-types/graphql";

const getSessionId = () =>
  `${Date.now().toString(36)}::${Math.random().toString(36).slice(2)}`;

const cardStyle = {
  height: "50vh",
};

const tools_display = [
  {
    tool: ChatToolType.DocumentsRagTool,
    name: "Document RAG",
    description: "Augments response with Document content",
  },
  {
    tool: ChatToolType.NotesRagTool,
    name: "Note RAG",
    description: "Augments response with Note content",
  },
];

export function RangerChat() {
  const params = useParams();

  const [sessionId, setSessionId] = useState(getSessionId());

  const [chatHistory, changeChatHistory] = useState<ChatMessage[]>([]);
  const [userText, setUserText] = useState("");
  const [conversationId, changeConversationId] = useState<number | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [tools, setTools] = useState<ChatToolType[]>([
    ChatToolType.NotesRagTool,
    ChatToolType.DocumentsRagTool,
    ChatToolType.CitationTool,
  ]);

  const [entityId, entityType] = useMemo(() => {
    if (params.investmentId) {
      return [Number(params.investmentId), types.ChatEntityType.Investment];
    } else if (params.companyId) {
      return [Number(params.companyId), types.ChatEntityType.Company];
    } else if (params.firmId) {
      return [Number(params.firmId), types.ChatEntityType.Firm];
    }
    return [undefined, undefined];
  }, [params]);

  const [sendChat, { loading }] = useLazyQuery(AGENT_CHAT, {
    variables: {
      question: userText,
      conversationId,
      chatContext: { entityId, entityType },
      tools,
      sessionId,
    },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      setIsGenerating(false);
      return;
    },
    onError(error) {
      changeChatHistory([
        ...chatHistory,
        {
          type: "agent",
          data: {
            type: "error",
            message: "Error answering your question, please try again",
          },
        },
      ]);
    },
  });

  useSubscription(CONTEXTUAL_CHAT_SUBSCRIPTION, {
    variables: {
      sessionId: sessionId,
    },
    skip: !sessionId,
    onData({ data }) {
      console.log(data);

      if (
        data.data?.rangerChatSubscription.messageStream.event === "stop" ||
        !data.data?.rangerChatSubscription.answer
      ) {
        return;
      }
      changeChatHistory([
        ...chatHistory.filter(
          x => x.id !== data.data?.rangerChatSubscription.messageStream.id
        ),
        {
          type: "agent",
          id: data.data?.rangerChatSubscription.messageStream.id,
          data: {
            type: "markdown",
            message: data.data?.rangerChatSubscription.answer,
          },
        },
        {
          type: "agent",
          id: data.data?.rangerChatSubscription.messageStream.id,
          data: {
            type: "react",
            message: (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {data.data?.rangerChatSubscription.tools?.map(
                    ({ tool, params, results }) => (
                      <ToolDisplays
                        tool={tool}
                        params={params}
                        results={results}
                      />
                    )
                  )}
                </div>
              </>
            ),
          },
        },
      ]);
    },
  });

  return (
    <>
      <ChatComponent
        chatMessages={[
          {
            type: "agent" as const,
            data: {
              type: "markdown" as const,
              message: `Welcome to Ranger Chat! Still in beta, our intelligent
                  assistant uses context from your notes and documents to
                  provide tailored insights on your ${entityType}
                  —helping you get precise answers quickly.`,
            },
          },
          ...chatHistory,
          ...(loading
            ? [
                {
                  type: "agent" as const,
                  data: {
                    type: "react" as const,
                    message: <Spin size="small" style={{ color: "white" }} />,
                  },
                },
              ]
            : []),
        ]}
        agentWorking={isGenerating}
        handleUserMessage={async () => {
          sendChat({
            variables: {
              question: userText,
              conversationId,
              chatContext: { entityId, entityType },
              tools,
              sessionId,
            },
          });
          changeChatHistory([
            ...chatHistory,
            { type: "user", data: { type: "markdown", message: userText } },
          ]);
          setUserText("");
          setIsGenerating(true);
        }}
        cardStyles={cardStyle}
        {...{ userText, setUserText }}
        agentCharacter={{
          name: "Ranger",
          src: "/assets/images/docchatIcon.png",
        }}
        onNewChat={() => {
          changeChatHistory([]);
          changeConversationId(null);
          setSessionId(getSessionId());
        }}
      />
      <Card>
        {tools_display.map(tool => (
          <Row key={tool.tool}>
            <Checkbox
              checked={tools.includes(tool.tool)}
              onChange={v =>
                v.target.checked
                  ? setTools([...tools, tool.tool])
                  : setTools(tools.filter(_tool => _tool !== tool.tool))
              }
            >
              {tool.name}
            </Checkbox>
          </Row>
        ))}
      </Card>
    </>
  );
}
