import React, { Suspense, lazy } from "react";
import { gql, useQuery } from "@apollo/client";
import { Alert } from "antd";
import Spinner from "frontend/src/components/Spinner";
import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
const get_tablau_token = gql`
  query tableauToken {
    tableauToken
  }
`;

const TableauDashboards = [
  {
    name: "ODC Portal",
    route: "odcPortal",
    sourceUrl:
      "https://10ay.online.tableau.com/#/site/therockcreekgroupllc/views/RockImpactPortalDashboard/Dashboard1?:iid=1",
  },
  {
    name: "Private Market Quartiles",
    route: "privateMarketQuartiles",
    sourceUrl:
      "https://10ay.online.tableau.com/#/site/therockcreekgroupllc/views/BurgissQuartilePerformanceTool/BurgissPeerQuartileReport?:iid=1",
  },
];

export const InternalDashboards = () => {
  const { data, loading, error } = useQuery(get_tablau_token);
  const renderLoader = () => <Spinner />;
  const { tab } = useParams();
  if (error) {
    return (
      <Alert
        message="Error"
        description="Error getting Tablau token. Please reload or contact support or try again."
        type="error"
        showIcon
      />
    );
  }

  if (!data) return null;

  const dashboardIndex = TableauDashboards.findIndex(
    dashboard => dashboard.route === tab
  );
  const tabIndex = dashboardIndex === -1 ? 0 : dashboardIndex;

  return (
    <div>
      <Tabs value={tabIndex}>
        {TableauDashboards.map(dashboard => (
          <Tab
            key={dashboard.route}
            label={dashboard.name}
            component={Link}
            to={`/internalDashboards/${dashboard.route}`}
          />
        ))}
      </Tabs>
      <div>
        <Suspense fallback={renderLoader()}>
          <TableauEmbed
            sourceUrl={TableauDashboards[tabIndex].sourceUrl}
            token={data.tableauToken}
            toolbar={"hidden"}
          />
        </Suspense>
      </div>
    </div>
  );
};
