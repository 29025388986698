/* eslint-disable react/sort-comp */
/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import TableColumnVisibility from "Components/common/table_column_visibility.js.jsx";
import {
  DownloadButtons,
  downloadSpreadsheet,
  SheetFormat,
} from "Helpers/downloadSpreadsheet";
import { isNegative, numberFromString } from "Helpers/index";
import i18n from "i18next";
import {
  ceil,
  cloneDeep,
  debounce,
  find,
  isEmpty,
  map,
  mergeWith,
  take,
} from "lodash";
import RcTooltip from "rc-tooltip";
import { Component } from "react";
import BenchmarkLayoutTypes from "../../../../models/benchmark_layout_types";
import Tooltip from "./tooltip";

const highlightNegatives = (value: number) => {
  if (isNegative(value)) {
    return "negative";
  }
  return "";
};

const subtractReturns =
  (ignoredKeys: string[]) =>
  (dataValue: string, benchmarkValue: string, key: string) => {
    if (ignoredKeys.includes(key)) {
      return dataValue;
    }

    if (dataValue && benchmarkValue) {
      const result = ceil(
        parseFloat(dataValue) - parseFloat(benchmarkValue),
        2
      );
      return result;
    }
    return null;
  };

type StatisticsTableProps = {
  visibilityDotsShown: boolean;
  dataMapping: any[] | void;
  valueCssClass: any;
  keyOrder: string[];
  headers: string[];
  renderCellValue: (
    value: string | number,
    defaultRender: (value: string | number) => any,
    mapping?: any,
    columnName?: string
  ) => any;
  ignoredBenchmarkKeys: string[];
  benchmarkDataMapping: any;
  benchmarkLookupKey: string;
  benchmarkLayoutType: any;
  sortableKeys: string[];
  numberKeys: string[];
  fileName: string;
  formatters: { [key: string]: (value: any) => any };
};

type StatisticsTableState = {
  rowsToTruncate: number;
  hasScrollBar: boolean;
  expanded: boolean;
  sort: { key: string | null; order: string };
  filterWindowKey: string | boolean;
  filters: any[];
};

class StatisticsTable extends Component<
  StatisticsTableProps,
  StatisticsTableState
> {
  // eslint-disable-next-line react/static-property-placement
  static get defaultProps() {
    return {
      visibilityDotsShown: false,
      valueCssClass: null,
      renderCellValue(value: string | number) {
        return value;
      },
      ignoredBenchmarkKeys: [],
      benchmarkDataMapping: [],
      benchmarkLayoutType: null,
      fileName: "statistics_table",
      sortableKeys: [],
      numberKeys: [],
      benchmarkLookupKey: "none",
      formatters: {},
    };
  }

  constructor(props: StatisticsTableProps) {
    super(props);
    this.state = {
      rowsToTruncate: 10,
      hasScrollBar: false,
      expanded: false,
      sort: { key: null, order: "ASC" },
      filterWindowKey: false,
      filters: [],
    };
  }

  componentDidMount() {
    this._handleResize = debounce(this.trackScrollable, 250);

    window.addEventListener("resize", this._handleResize);
    this.trackScrollable();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._handleResize);
  }

  scrollWrap: any = {};

  _handleResize: any = () => {};

  getRows(expanded: boolean, excludedFilterKey?: string) {
    const {
      dataMapping,
      benchmarkDataMapping,
      numberKeys,
      benchmarkLookupKey,
      ignoredBenchmarkKeys,
    } = this.props;
    const { rowsToTruncate, sort, filters } = this.state;
    const includedFilters = filters.filter(
      filter => excludedFilterKey !== filter.key
    );
    if (!dataMapping) return [];
    let combinedDataMapping = dataMapping.map(data => {
      const benchmarkData =
        find(benchmarkDataMapping, {
          [benchmarkLookupKey]: data[benchmarkLookupKey],
        }) || {};
      const combinedData = mergeWith(
        cloneDeep(data),
        benchmarkData,
        subtractReturns(ignoredBenchmarkKeys)
      );
      return {
        data,
        benchmarkData,
        combinedData,
      };
    });

    if (sort.key) {
      const { key, order } = sort;

      combinedDataMapping.sort((a, b) => {
        if (!a || !b) return 0;
        if (typeof a.data[key] === "string" && !numberKeys.includes(key)) {
          const aText = a.data[key] ? a.data[key].toUpperCase() : "";
          const bText = b.data[key] ? b.data[key].toUpperCase() : "";
          if (order === "DESC") {
            return aText > bText ? -1 : aText < bText ? 1 : 0;
          }
          return aText < bText ? -1 : aText > bText ? 1 : 0;
        }
        if (order === "DESC") {
          if (typeof a.data[key] === "string") {
            return (
              numberFromString(b.data[key]) - numberFromString(a.data[key])
            );
          }
          return b.data[key] - a.data[key];
        }
        if (typeof a.data[key] === "string") {
          return numberFromString(a.data[key]) - numberFromString(b.data[key]);
        }
        return a.data[key] - b.data[key];
      });
    }

    if (includedFilters.length) {
      combinedDataMapping = combinedDataMapping.filter(({ data }) =>
        includedFilters.every(({ key, values }) => values.includes(data[key]))
      );
    }

    return expanded
      ? combinedDataMapping
      : take(combinedDataMapping, rowsToTruncate);
  }

  hasMoreRows() {
    if (!this.props.dataMapping) return false;
    return this.state.rowsToTruncate < this.props.dataMapping.length;
  }

  toogleRows = () => {
    const { expanded } = this.state;

    this.setState({
      expanded: !expanded,
    });
  };

  canShowVisibilityDots() {
    return this.props.visibilityDotsShown && this.scrollWrap;
  }

  download = (format: SheetFormat) => {
    const { keyOrder, headers, fileName, benchmarkDataMapping } = this.props;
    const sheets = [
      {
        name: "Sheet 1",
        data: [
          headers,
          ...this.getRows(true).map(row =>
            keyOrder.map(mapping => {
              if (row.data[mapping]) {
                if (row.data[mapping].props) {
                  return row.data[mapping].props.children;
                }
                return row.data[mapping] ?? null;
              }
              if (row.data[mapping] === 0) {
                return 0;
              }
            })
          ),
        ],
      },
    ];
    if (benchmarkDataMapping.length) {
      sheets.push({
        name: "Benchmark Data",
        data: [
          headers,
          ...this.getRows(true).map(row =>
            keyOrder.map(mapping => {
              if (row.benchmarkData[mapping]) {
                if (row.benchmarkData[mapping].props) {
                  return row.benchmarkData[mapping].props.children;
                }
                return row.benchmarkData[mapping] ?? null;
              }
            })
          ),
        ],
      });
    }
    downloadSpreadsheet(sheets, fileName, format);
  };

  trackScrollable = () => {
    if (!this.scrollWrap) return;

    const { scrollWidth, clientWidth } = this.scrollWrap;

    this.setState({
      hasScrollBar: scrollWidth > clientWidth,
    });
  };

  renderRows() {
    const rows = this.getRows(this.state.expanded);

    return map(rows, (mapping, index) => (
      <tr
        key={`row-${index}`}
        className="hover-bg-blue-grey-50 summary-table-row"
        role="data-row"
      >
        {this.renderData(mapping)}
      </tr>
    ));
  }

  renderData({
    data,
    benchmarkData,
    combinedData,
  }: {
    data: any;
    benchmarkData: any;
    combinedData: any;
  }) {
    const { benchmarkLayoutType, keyOrder } = this.props;
    const isCombination =
      BenchmarkLayoutTypes.isCombination(benchmarkLayoutType);

    const isDelta = BenchmarkLayoutTypes.isDelta(benchmarkLayoutType);

    return map(keyOrder, (columnName, index) => (
      <td
        key={columnName + index}
        className="summary-table-header-td field-popover__exist"
      >
        {this.renderValue(isDelta ? combinedData : data, columnName)}

        {isCombination && this.renderBenchmarkData(benchmarkData, columnName)}
      </td>
    ));
  }

  renderBenchmarkData(benchmarkData: any, columnName: string) {
    const { ignoredBenchmarkKeys, formatters } = this.props;
    if (isEmpty(benchmarkData) || ignoredBenchmarkKeys.includes(columnName)) {
      return null;
    }

    return (
      <p className="performance-statistics-table__combination-value">
        {(formatters[columnName]
          ? formatters[columnName](benchmarkData[columnName])
          : benchmarkData[columnName]) ?? "-"}
      </p>
    );
  }

  renderValue(mapping: any, columnName: string) {
    const { formatters } = this.props;
    let value;
    if (formatters[columnName]) {
      value = formatters[columnName](mapping[columnName]) ?? "-";
    } else {
      value = mapping[columnName] ?? "-";
    }

    return (
      <div
        className={`${columnName} ${this.renderValueStyle(
          mapping,
          columnName
        )}`}
      >
        <span className="p-relative">
          {this.props.renderCellValue(
            value,
            StatisticsTable.defaultProps.renderCellValue,
            mapping,
            columnName
          )}
        </span>
      </div>
    );
  }

  renderValueStyle(mapping: any, columnName: string) {
    let extraClass = "";

    if (this.props.valueCssClass) {
      extraClass = this.props.valueCssClass(mapping, columnName);
    }

    return [highlightNegatives(mapping[columnName]), extraClass].join(" ");
  }

  renderExpander() {
    const { expanded } = this.state;
    const iconClassNames = classNames(
      "switch-btn__icon icon icon--20",
      { "icon-arrow-up": expanded },
      { "icon-arrow": !expanded }
    );
    const label = expanded
      ? i18n.t("statistics_table.show_less")
      : i18n.t("statistics_table.show_more");

    return (
      this.hasMoreRows() && (
        <tr>
          <td colSpan={100} className="text-center">
            <button
              type="button"
              className="switch-btn"
              id="show-more-button"
              onClick={this.toogleRows}
            >
              {label}
              <span className={iconClassNames} />
            </button>
          </td>
        </tr>
      )
    );
  }

  renderHeaders() {
    const { headers, keyOrder, sortableKeys, formatters } = this.props;
    const { filterWindowKey } = this.state;

    return map(headers, (key, i) => (
      <th key={i} className="summary-table-header-th">
        <div className="summary-table-header-th-text">
          {key}
          {sortableKeys.includes(keyOrder[i]) && (
            <RcTooltip
              destroyTooltipOnHide
              visible={keyOrder[i] === filterWindowKey}
              onVisibleChange={visible => {
                if (visible) {
                  this.setState({ filterWindowKey: keyOrder[i] });
                } else {
                  this.setState({ filterWindowKey: false });
                }
              }}
              trigger={["click"]}
              placement="bottomLeft"
              overlayClassName="fund-tooltip-wrap"
              overlay={
                <Tooltip
                  sort={this.state.sort}
                  formatters={formatters}
                  keyName={keyOrder[i]}
                  close={() => {
                    this.setState({ filterWindowKey: false });
                  }}
                  changeSort={sort => this.setState({ sort })}
                  filterOptions={this.getRows(true, keyOrder[i])
                    .map(({ data }) => {
                      return data[keyOrder[i]];
                    })
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )}
                  localFilter={this.state.filters.find(
                    filter => filter.key === keyOrder[i]
                  )}
                  addFilter={(key, values) => {
                    this.setState(state => {
                      const { filters } = state;
                      const existing = filters.find(
                        filter => filter.key === key
                      );
                      const newState = {
                        ...state,
                        filters: [
                          ...filters.filter(filter => filter.key !== key),
                          {
                            key,
                            values: Array.from(
                              new Set([
                                ...(existing ? existing.values : []),
                                ...values,
                              ])
                            ),
                          },
                        ],
                      };
                      return newState;
                    });
                  }}
                  removeFilter={(key, value) => {
                    this.setState(state => {
                      const { filters } = state;
                      const existing = filters.find(
                        filter => filter.key === key
                      );
                      const newState = {
                        ...state,
                        filters: [
                          ...filters.filter(filter => filter.key !== key),
                          {
                            key,
                            values: existing.values.filter(
                              (v: string) => v !== value
                            ),
                          },
                        ],
                      };
                      return newState;
                    });
                  }}
                  clearFilter={() =>
                    this.setState({
                      filters: this.state.filters.filter(
                        ({ key }) => key !== keyOrder[i]
                      ),
                    })
                  }
                />
              }
            >
              <i
                className="fa fa-filter"
                data-testid={`${keyOrder[i]} filter`}
                onClick={() => {
                  this.setState({ filterWindowKey: keyOrder[i] });
                }}
              />
            </RcTooltip>
          )}
        </div>
      </th>
    ));
  }

  render(): JSX.Element {
    return (
      <>
        <div
          className={classNames(
            "overview-scrollable-table",
            { "scrollable-table": this.state.hasScrollBar },
            { "scrollable-table--hidden": !this.state.hasScrollBar }
          )}
        >
          {this.canShowVisibilityDots() && (
            <TableColumnVisibility scrollContainer={this.scrollWrap} />
          )}
          <div
            className="scrollable-table__wrap"
            ref={el => {
              this.scrollWrap = el;
            }}
          >
            <table
              data-cy="statistics-table"
              data-testid="statistics-table"
              className={classNames("border-radius-3 font-14", "full-width")}
            >
              <thead>
                <tr className="bg-white summary-table-row">
                  {this.renderHeaders()}
                </tr>
              </thead>

              <tbody>{this.renderRows()}</tbody>

              <tfoot>{this.renderExpander()}</tfoot>
            </table>
          </div>
        </div>
        <DownloadButtons download={this.download} />
      </>
    );
  }
}

export default StatisticsTable;
