import React from "react";
import { ChatToolType } from "_graphql-types/graphql";
import { FileOutlined, FormOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { DOCUMENT_SHOW_QUERY } from "Components/show/documents/graphql";
import { Tooltip } from "antd";

type DocumentsRagToolParams = {
  query: string;
};

const DocumentsRagTool = ({ params }: { params: DocumentsRagToolParams }) => {
  return (
    <div
      data-cy="documents-rag-tool"
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
      }}
    >
      <div>
        <FileOutlined />
        <span style={{ marginLeft: 5 }}>Document RAG</span>
      </div>
      <div style={{ fontSize: 8, color: "gray" }}>{params.query}</div>
    </div>
  );
};

type NotesRagToolParams = {
  query: string;
  "Min-Date": string;
};

const NotesRagTool = ({ params }: { params: NotesRagToolParams }) => {
  return (
    <div
      data-cy="notes-rag-tool"
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
      }}
    >
      <div>
        <FormOutlined />
        <span style={{ marginLeft: 5 }}>Note RAG</span>
      </div>
      <div style={{ fontSize: 8, color: "gray" }}>{params.query}</div>
      {params["Min-Date"] && (
        <div
          style={{ fontSize: 8, color: "gray" }}
        >{`Min Date: ${params["Min-Date"]}`}</div>
      )}
    </div>
  );
};

type CitationToolResults = {
  citationType: "documentExerpt" | "note";
  citationId: number;
  indexStart: number;
  indexEnd: number;
};

const DocumentLink = ({
  documentId,
  supportingText,
}: {
  documentId: number;
  supportingText: string;
}) => {
  const { data, loading } = useQuery(DOCUMENT_SHOW_QUERY, {
    variables: { id: documentId },
  });
  if (loading) {
    return null;
  }
  return (
    <a href={`/documents/${documentId}`} target="_blank">
      {loading ? "Loading" : data?.document?.name}
    </a>
  );
};

const CitationTool = ({
  results,
}: {
  results: {
    citationType: string;
    citationId: number;
    documentId?: number;
    supportingText?: string;
  };
}) => {
  const { citationType, citationId, documentId, supportingText } = results;
  if (citationType === "documentExerpt" && documentId) {
    return (
      <DocumentLink
        documentId={documentId}
        supportingText={supportingText || ""}
      />
    );
  }

  return null;
};

export const ToolDisplays = ({
  tool,
  params,
  results,
}:
  | {
      tool: ChatToolType.DocumentsRagTool;
      params: DocumentsRagToolParams;
      results: undefined;
    }
  | {
      tool: ChatToolType.NotesRagTool;
      params: NotesRagToolParams;
      results: undefined;
    }
  | {
      tool: ChatToolType.CitationTool;
      params: null;
      results: CitationToolResults;
    }) => {
  switch (tool) {
    case ChatToolType.DocumentsRagTool:
      return <DocumentsRagTool {...{ params }} />;
    case ChatToolType.NotesRagTool:
      return <NotesRagTool {...{ params }} />;
    case ChatToolType.CitationTool:
      return <CitationTool {...{ params, results }} />;
    default:
      return <div>Unknown tool</div>;
  }
};
